import { useQuery } from 'react-query';
import { logger, fetcher } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

export type ShareScheduleResponse = {
  ok: boolean;
  data: {
    name: string;
    schedules: Array<{
      scheduleId: number;
      sessionId: number;
      title: string;
      data: {
        tag_id: number | null;
        has_post: boolean;
        trackName: string;
        affiliation: string;
        speakerName: string;
        backgroundColor: string;
        sessionLocation: string;
        sessionSchedule: string;
      };
    }>;
  };
};

const useUserSchedule = (userId: number, hash: string, year: number = 2023) => {
  return useQuery('user-schedule', async () => {
    try {
      const response = await fetcher.get<ShareScheduleResponse>(
        API_URLS.USER_SCHEDULE(userId, hash, year)
      );

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useUserSchedule;
